<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToList"
        >
          <i class="ion ion-ios-arrow-back" />
          Back to List
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted" />
      </div>
    </div>
    <div class="row connected-profiles">
      <div class="col-md-12 d-flex align-items-center">
        Connected Profiles:
        <div
          class="ml-3"
          style="max-width: 150px"
        >
          <cp-image
            class="securitize-id-logo"
            :src="getImagePath('securitize-id-logo.svg', 'logo')"
            :default-src="getImagePath('logo.png', 'logo')"
            @click="goToSecuritizeId"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-end row-overwrite justify-content-between">
      <div class="py-3">
        <h4 class="font-weight-bold">
          {{ brokerDealerInvestor.fullName }}
        </h4>
        <div class="d-flex align-items-center">
          <span class="text-muted">
            {{ brokerDealerInvestor.email }}
          </span>
        </div>
      </div>
    </div>
    <div class="row align-items-center row-overwrite mb-4">
      <div class="mr-4">
        <span class="account-status-label">US Account Status: </span>
        <b-badge :class="accountStatuses[brokerDealerInvestor.accountStatus]">
          {{ brokerDealerInvestor.accountStatus }}
        </b-badge>
      </div>
      <div class="mr-4">
        <span class="account-status-label">EU Account Status: </span>
        <b-badge :class="accountStatuses[brokerDealerInvestor.accountEuStatus]">
          {{ brokerDealerInvestor.accountEuStatus }}
        </b-badge>
      </div>
      <div>
        <span class="account-status-label">Cash Account Status: </span>
        <b-badge
          :class="cashAccountStatuses[brokerDealerInvestor.cashAccountStatus] || 'badge-grey'"
        >
          {{ formatCashAccountStatus(brokerDealerInvestor.cashAccountStatus) }}
        </b-badge>
      </div>
    </div>
    <market-investor-tabs :investor-type="brokerDealerInvestor.investorType" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import routeNames from '~/utilities/routes';
import MarketInvestorTabs from '@/pages/broker-dealer/investors/components/details/tabs/market-investor-tabs';
import { accountStatuses, cashAccountStatuses } from '@/pages/broker-dealer/investors/components/options';
import CpImage from '../../../components/common/standalone-components/cp-image.vue';
import CpStaticPathGenerationMixin from '../../../mixins/static-path-generation';


export default {
  name: 'BrokerDealerInvestor',
  metaInfo: {
    title: 'Investor Details',
  },
  components: {
    MarketInvestorTabs,
    CpImage,
  },
  mixins: [
    CpStaticPathGenerationMixin,
  ],
  props: {
    linkToList: {
      type: String,
      default: '/markets/investors', // replace with "required: true" when list-page is ready
    },
  },
  data() {
    return {
      updateTable: false,
      investorId: this.$route.params.investorId,
      isReady: false,
      accountStatuses,
      cashAccountStatuses,
    };
  },
  computed: {
    ...mapGetters('global', ['currencies']),
    ...mapState('brokerDealerInvestors', ['brokerDealerInvestor']),
    getInvestorTitle() {
      return this.brokerDealerInvestor.fullName;
    },
  },
  created() {
    this.getBrokerDealerInvestorId({ investorId: this.investorId })
      .then(() => {
        this.isReady = true;
      });
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getBrokerDealerInvestorId']),
    doUpdateTable() {
      this.updateTable = true;
    },
    sendInviteModal() {
      this.$refs.CpSendInviteEmail.show();
    },
    goToList() {
      const query = this.$route.fullPath.split('?')[1];
      const route = routeNames.brokerDealerInvestors();
      const path = query ? `${route}?${query}` : route;
      this.$router.push(path);
    },
    formatCashAccountStatus(status) {
      if (!status) return 'Status not available';
      if (status === 'none') return 'None';
      return status === 'opened-with-sid' ? 'Opened with SID' : 'Opened with BD';
    },
    goToSecuritizeId() {
      window.open(routeNames.securitizeIdInvestor(this.brokerDealerInvestor.investorId), '_blank');
    },
  },

};
</script>

<style src="~/vendor/styles/pages/users.scss" lang="scss"></style>
<style scoped lang="scss">
  .ion-ios-arrow-back {
    padding-right: 5px;
  }

  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0;

    h4 {
      margin: 0;
    }
  }

  .row.row-overwrite {
    margin-right: 0;
    margin-left: 0;
  }

  .primary {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  }

  .badge-grey, .badge-orange, .badge-yellow, .badge-green, .badge-red {
    text-transform: capitalize;
    padding: 0.5em 1em;
    border-radius: 2px;
    color: white;
  }
  .badge-grey {
    background-color: #dee0e2;
    -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
    box-shadow: 0 0 0 1px #dee0e2 inset;
  }
  .badge-orange {
    background-color: #F5A01D;
    -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
    box-shadow: 0 0 0 1px #F5A01D inset;
  }
  .badge-yellow {
    background-color: #ffd950;
    -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
    box-shadow: 0 0 0 1px #ffd950 inset;
  }
  .badge-green {
    background-color: #07bc77;
    -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
    box-shadow: 0 0 0 1px #07bc77 inset;
  }
  .badge-red {
    background-color: #f7321f;
    -webkit-box-shadow: 0 0 0 1px #f7321f inset;
    box-shadow: 0 0 0 1px #f7321f inset;
  }

  .account-status-label {
    font-size: 12px;
    color: #9da2aa;
    margin-right: 0.5em;
  }
  .connected-profiles{
    .securitize-id-logo{
      cursor: pointer;
    }
  }
</style>
