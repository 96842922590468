<template>
  <div
    id="account-log-table"
    class="w-100 mt-3"
  >
    <cp-table
      ref="cpTable"
      :no-local-sorting="false"
      default-sort-by="createdAt"
      get-data-action="brokerDealerInvestors/getAccountStatusHistory"
      :data-parser="parseData"
      :fields="accountLogTableFields"
      table-title="Account status Log"
      :without-search="true"
      :without-pagination="true"
      :url-params="{ investorId }"
    >
      <template
        slot="createDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.createDate | dateFilter }}
        </span>
      </template>
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom ml-1"
          :class="accountStatuses[rowData.item.status]"
        >
          {{ rowData.item.status }}
        </b-badge>
      </template>
      <template
        slot="operator"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.operator }}
        </span>
      </template>
    </cp-table>
  </div>
</template>

<script>
import CpTable from '@/components/shared/cp-table/index';
import CpTimeConverter from '@/mixins/time-converter';
import {
  accountLogTableFields,
  accountStatuses,
} from '@/pages/broker-dealer/investors/components/options';

export default {
  name: 'AccountStatusLogTable',
  components: {
    CpTable,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    investorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      accountStatuses,
      accountLogTableFields,
      totalTableData: [],
    };
  },
  methods: {
    parseData(data) {
      return data.filter(accountStatusLog => !accountStatusLog.isEU);
    },
  },
};
</script>

<style lang="scss">
  #account-log-table {
    .table-responsive {
      max-height: 175px;
      overflow: auto;
      min-height: auto;
    }
  }
  .badge-grey {
    text-transform: capitalize;
    background-color: #dee0e2;
    -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
    box-shadow: 0 0 0 1px #dee0e2 inset;
    padding: 0.5em 1em;
    border-radius: 2px;
  }
  .badge-orange {
    text-transform: capitalize;
    background-color: #F5A01D;
    -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
    box-shadow: 0 0 0 1px #F5A01D inset;
    padding: 0.5em 1em;
    border-radius: 2px;
  }
  .badge-yellow {
    text-transform: capitalize;
    background-color: #ffd950;
    -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
    box-shadow: 0 0 0 1px #ffd950 inset;
    padding: 0.5em 1em;
    border-radius: 2px;
  }
  .badge-green {
    text-transform: capitalize;
    background-color: #07bc77;
    -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
    box-shadow: 0 0 0 1px #07bc77 inset;
    padding: 0.5em 1em;
    border-radius: 2px;
  }
  .badge-red {
    text-transform: capitalize;
    background-color: #f7321f;
    -webkit-box-shadow: 0 0 0 1px #f7321f inset;
    box-shadow: 0 0 0 1px #f7321f inset;
    padding: 0.5em 1em;
    border-radius: 2px;
  }
</style>
