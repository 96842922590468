<template>
  <div class="card mb-5">
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            {{ isConfidential ? 'Confidential Documents' : 'Documents' }}
          </h4>
        </div>
      </div>
    </div>

    <!--Documents table-->
    <cp-table
      ref="cpTable"
      without-search
      without-pagination
      :get-data-action="`brokerDealerInvestors/${isConfidential ?
        'getBrokerDealerInvestorConfidentialDocuments' :
        'getBrokerDealerInvestorDocuments'}`"
      :url-params="urlParams"
      :fields="documentsFields"
      :empty-table-message="emptyTableMessage"
    >
      <template
        slot="thumbnail"
        slot-scope="{ rowData }"
      >
        <img
          :ref="rowData.item.documentId"
          class="ui-w-40 d-block link"
          :src="getImageIcon(rowData.item)"
          style="cursor: pointer"
          alt="Document preview"
          @click="showPreviewDocModal(rowData.item)"
          @error="showFallbackImage(rowData.item)"
        >
      </template>
      <template
        slot="docType"
        slot-scope="{ rowData }"
      >
        {{ getDocType(rowData.item.docType) }}
      </template>
      <template
        slot="createDate"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.createDate | dateFilter }}
      </template>
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          :class="documentsVerificationStatuses[rowData.item.verificationStatus]"
        >
          {{ rowData.item.verificationStatus }}
        </b-badge>
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            icon="ios-arrow-round-down"
            variant="outline-primary"
            :disabled="downloadingDocuments[rowData.item.documentId]"
            :is-loading="downloadingDocuments[rowData.item.documentId]"
            @click="downloadDocument(rowData.item)"
          >
            Download
          </cp-button>
        </div>
      </template>
    </cp-table>

    <!--modals-->

    <preview-document-modal
      ref="previewDocModal"
      :document-id="documentId"
      :investor-id="urlParams.investorId"
      :file-type="fileType"
      :is-confidential="isConfidential"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import documents, { documentsVerificationStatuses } from '../../options';
import PreviewDocumentModal from './preview-document-modal';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpTable from '@/components/shared/cp-table/index';
import staticPathGeneration from '@/mixins/static-path-generation';


export default {
  name: 'InvestorDocuments',
  components: {
    CpButton,
    PreviewDocumentModal,
    CpTable,
  },
  mixins: [staticPathGeneration],
  props: {
    isConfidential: Boolean,
  },
  data() {
    return {
      fileType: '',
      documentId: null,
      downloadingDocuments: {},
      deleteItem: null,
      categories: documents.categories,
      emptyTableMessage: 'There are no documents.',
      documentsVerificationStatuses,
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
    urlParams() {
      return { investorId: this.getGeneralInfo.investorId };
    },
    documentsFields() {
      return documents.getFields(this.isConfidential);
    },
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['downloadBrokerDealerInvestorDocumentById']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    getImageIcon({ thumbnail, fileType }) {
      return fileType === 'application/pdf'
        ? this.getImagePath('fallback-pdf.jpg')
        : thumbnail || this.getImagePath('fallback-image.jpg');
    },
    showFallbackImage({ documentId }) {
      this.$refs[documentId].src = this.getImagePath('fallback-image.jpg');
    },
    showPreviewDocModal({ documentId, fileType }) {
      this.documentId = documentId;
      this.fileType = fileType;
      this.$refs.previewDocModal.showPreview();
    },
    downloadDocument({ documentId }) {
      this.downloadingDocuments[documentId] = true;
      this.downloadBrokerDealerInvestorDocumentById({
        ...this.urlParams,
        documentId,
        isConfidential: this.isConfidential,
      }).then(
        ({ data: { url } }) => {
          if (url) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = url.split('/').pop();
            link.href = url;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER('Failed to fetch file from server');
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[documentId];
      });
    },
    getDocType(docType) {
      const { investorType, entityType } = this.getGeneralInfo;
      if (investorType === 'entity' && entityType) {
        return this.findType(this.categories.documentsTypeEntity[entityType], docType);
      }
      return this.findType(this.categories.documentTypeIndividual, docType);
    },
    findType(types, type) {
      const found = types.find(({ value }) => value.docType === type);
      if (!found) return type;
      return found.text;
    },
  },
};
</script>

<style lang="scss" scoped>
  .badge-orange {
    text-transform: capitalize;
    background-color: #F5A01D;
    -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
    box-shadow: 0 0 0 1px #F5A01D inset;
    padding: 0.5em 1em;
    border-radius: 2px;
    color: white !important;
  }
  .badge-green {
    text-transform: capitalize;
    background-color: #07bc77;
    -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
    box-shadow: 0 0 0 1px #07bc77 inset;
    padding: 0.5em 1em;
    border-radius: 2px;
    color: white !important;
  }
  .badge-red {
    text-transform: capitalize;
    background-color: #f7321f;
    -webkit-box-shadow: 0 0 0 1px #f7321f inset;
    box-shadow: 0 0 0 1px #f7321f inset;
    padding: 0.5em 1em;
    border-radius: 2px;
    color: white !important;
  }
</style>
