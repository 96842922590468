<template>
  <div
    class="card mb-5"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('brokerDealer.investorDetails.verificationTab.identityCheckTitle') }}
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex flex-column align-items-start justify-content-between flex-wrap">
        <div>
          <b-badge
            class="align-text-bottom mb-3"
            :class="kycStatuses[getGeneralInfo.verificationStatus]"
          >
            {{ calculateStatus(getGeneralInfo) }}
          </b-badge>
          <b-badge
            v-if="getGeneralInfo.verificationSubStatus === 'enhance-requested'"
            class="align-text-bottom badge-grey ml-1 mb-3"
          >
            {{ getGeneralInfo.verificationSubStatus }}
          </b-badge>
        </div>
        <div class="status-current">
          <div class="text-muted small">
            {{ $t('brokerDealer.investorDetails.verificationTab.identityCheckInfo') }}
          </div>
        </div>
      </div>
      <div class="mt-3">
        <p>
          {{ $t('brokerDealer.investorDetails.verificationTab.ssnCheckResults') }}
        </p>
        <cp-button
          variant="primary"
          :disabled="!idologyCheckId"
          @click="openIdologyReport"
        >
          {{ $t('securitizeId.kyc.label.viewExternalReport') }}
        </cp-button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import CpButton from '@/components/common/standalone-components/cp-button';
import { kycStatuses } from '../../options';

export default {
  name: 'InvestorDetailsKyc',
  components: {
    CpButton,
  },
  data() {
    return {
      kycStatuses,
      idologyCheckId: null,
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
  },
  async created() {
    const { data } = await this.getInvestorKycHistory({ params: { investorId: this.getGeneralInfo.investorId } });
    const idology = data.find(item => !!item.idologyCheckId) || {};
    this.idologyCheckId = idology.idologyCheckId;
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getInvestorKycHistory']),
    calculateStatus(item) {
      const blocked = item.verificationStatus === 'rejected'
          && item.verificationSubStatus === 'blocked'
          && 'rejected[blocked]';
      const documentsExpired = item.verificationStatus === 'verified'
          && item.verificationSubStatus === 'documents-expired'
          && 'verified[documents-expired]';
      const expired = item.verificationStatus === 'verified'
          && item.verificationSubStatus === 'expired'
          && 'verified[expired]';
      const enhanceVerified = item.verificationStatus === 'verified'
          && item.verificationLevel === 'enhanced'
          && 'enhance-verified';

      return blocked || documentsExpired || expired || enhanceVerified || item.verificationStatus.replace('-', ' ');
    },
    openIdologyReport() {
      if (this.idologyCheckId) {
        window.open(`https://web.idologylive.com/reports/query.php?id=${this.idologyCheckId}`, '__blank');
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .badge-grey, .badge-orange, .badge-yellow, .badge-green, .badge-red {
    text-transform: capitalize;
    padding: 0.5em 1em;
    border-radius: 2px;
    color: white;
  }
  .badge-grey {
    background-color: #dee0e2;
    -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
    box-shadow: 0 0 0 1px #dee0e2 inset;
  }
  .badge-orange {
    background-color: #F5A01D;
    -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
    box-shadow: 0 0 0 1px #F5A01D inset;
  }
  .badge-yellow {
    background-color: #ffd950;
    -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
    box-shadow: 0 0 0 1px #ffd950 inset;
  }
  .badge-green {
    background-color: #07bc77;
    -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
    box-shadow: 0 0 0 1px #07bc77 inset;
  }
  .badge-red {
    background-color: #f7321f;
    -webkit-box-shadow: 0 0 0 1px #f7321f inset;
    box-shadow: 0 0 0 1px #f7321f inset;
  }
</style>
