<template>
  <div>
    <broker-dealer-general-information />
    <broker-dealer-legal-signers-table
      v-if="investorType === 'entity'"
      @onViewElem="navigateToEditLegalSigner"
    />
    <investor-documents />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BrokerDealerGeneralInformation from '@/pages/broker-dealer/investors/components/details/general/index';
import BrokerDealerLegalSignersTable
  from '@/pages/broker-dealer/investors/components/details/legal-signer/legal-signers-table';
import InvestorDocuments from '@/pages/broker-dealer/investors/components/details/documents/index';
import routeNames from '@/utilities/routes';

export default {
  name: 'GeneralInfoTab',
  components: {
    InvestorDocuments,
    BrokerDealerLegalSignersTable,
    BrokerDealerGeneralInformation,
  },
  props: {
    investorType: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
    navigateToEditLegalSigner({ signerId: legalSignerId }) {
      if (legalSignerId && this.getGeneralInfo().investorId) {
        this.$router.push(routeNames.brokerDealerLegalSigner({ legalSignerId, investorId: this.getGeneralInfo().investorId }));
      }
    },
  },
};
</script>
