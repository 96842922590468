var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.isConfidential ? 'Confidential Documents' : 'Documents')+" ")])])])]),_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","without-pagination":"","get-data-action":("brokerDealerInvestors/" + (_vm.isConfidential ?
      'getBrokerDealerInvestorConfidentialDocuments' :
      'getBrokerDealerInvestorDocuments')),"url-params":_vm.urlParams,"fields":_vm.documentsFields,"empty-table-message":_vm.emptyTableMessage},scopedSlots:_vm._u([{key:"thumbnail",fn:function(ref){
      var rowData = ref.rowData;
return [_c('img',{ref:rowData.item.documentId,staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(rowData.item),"alt":"Document preview"},on:{"click":function($event){return _vm.showPreviewDocModal(rowData.item)},"error":function($event){return _vm.showFallbackImage(rowData.item)}}})]}},{key:"docType",fn:function(ref){
      var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getDocType(rowData.item.docType))+" ")]}},{key:"createDate",fn:function(ref){
      var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createDate))+" ")]}},{key:"status",fn:function(ref){
      var rowData = ref.rowData;
return [_c('b-badge',{class:_vm.documentsVerificationStatuses[rowData.item.verificationStatus]},[_vm._v(" "+_vm._s(rowData.item.verificationStatus)+" ")])]}},{key:"actions",fn:function(ref){
      var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments[rowData.item.documentId],"is-loading":_vm.downloadingDocuments[rowData.item.documentId]},on:{"click":function($event){return _vm.downloadDocument(rowData.item)}}},[_vm._v(" Download ")])],1)]}}])}),_c('preview-document-modal',{ref:"previewDocModal",attrs:{"document-id":_vm.documentId,"investor-id":_vm.urlParams.investorId,"file-type":_vm.fileType,"is-confidential":_vm.isConfidential}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }