<template>
  <div
    class="card mb-5"
    :class="isLoading?'sec-saving':''"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            General information
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="getGeneralInfo"
      >
        <div class="mb-4 bg-light full-width">
          <div :class="['col-md-6', 'd-flex', 'investor-type-field']">
            <span>Investor Type: </span>
            <strong
              class="investor-type-value"
            >
              {{ getGeneralInfo.investorType }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Name:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.name | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--Name-->
                <tr v-if="isEntity">
                  <td>Entity DBA:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.entityDba | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--entityDba-->
                <tr v-if="isIndividual">
                  <td>First Name:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.firstName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--firstName-->
                <tr v-if="isIndividual">
                  <td>Middle Name:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.middleName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--middleName-->
                <tr v-if="isIndividual">
                  <td>Last Name:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.lastName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--lastName-->
                <tr>
                  <td>Email:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.email }}
                    </strong>
                  </td>
                </tr><!--email-->
                <tr v-if="isEntity">
                  <td>Type:</td>
                  <td>
                    <strong>
                      {{ $t(`securitizeId.entityType["${getGeneralInfo.entityType}"]`) || getGeneralInfo.entityType }}
                    </strong>
                  </td>
                </tr><!--entityType-->
                <tr v-if="isIndividual">
                  <td>Phone Prefix:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.phonePrefix | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--phonePrefix-->
                <tr v-if="isIndividual">
                  <td>Phone:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.phoneNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--phoneNumber-->
                <tr v-if="isIndividual">
                  <td>Birthdate</td>
                  <td>
                    <strong>
                      <template v-if="getGeneralInfo.birthDay">
                        {{ getGeneralInfo.birthDay | dateFilterWOTz }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </strong>
                  </td>
                </tr><!--birthdate-->
                <tr v-if="isIndividual">
                  <td>Gender:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.gender | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--gender-->
                <tr>
                  <td>
                    Country:
                  </td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('countryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--countryCode-->
                <tr v-if="model.countryCode === 'US'">
                  <td>
                    State:
                  </td>
                  <td>
                    <strong>
                      {{ generalDetailState | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--state-->
                <tr>
                  <td>City:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.city | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--city-->
                <tr>
                  <td>Postal Code:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.postalCode | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--postalCode-->
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Tax ID:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.taxId1 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId-->
                <tr v-if="isEntity">
                  <td>Tax Country (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode1') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode-->
                <tr>
                  <td>Street Name:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.streetName | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--streetName-->
                <tr>
                  <td>Street Number:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.streetNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--streetNumber-->
                <tr>
                  <td>Additional Info:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.addressAdditionalInfo | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--addressAdditionalInfo-->
                <tr v-if="isIndividual">
                  <td>Tax ID:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.taxId1 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId1-->
                <tr v-if="isIndividual">
                  <td>Tax Country 1 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode1') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode1-->
                <tr v-if="isIndividual">
                  <td>Tax ID 2:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.taxId2 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId2-->
                <tr v-if="isIndividual">
                  <td>Tax Country 2 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode2') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode2-->
                <tr v-if="isIndividual">
                  <td>Tax ID 3:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.taxId3 | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId3-->
                <tr v-if="isIndividual">
                  <td>Tax Country 3 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode3') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode3-->
                <tr v-if="isIndividual">
                  <td>Identity Document Number:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.identityDocumentNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--Identity Document Number-->
                <tr v-if="isIndividual">
                  <td>Country of Birth:</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('countryOfBirth') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--countryOfBirth-->
                <tr v-if="isIndividual">
                  <td>City of Birth:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.cityOfBirth | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--cityOfBirth-->
                <tr v-if="isEntity">
                  <td>Incorporation date:</td>
                  <td>
                    <strong>
                      {{ (getGeneralInfo.incorporationDate || null) | dateFilterWOTz | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--incorporationDate-->
                <tr>
                  <td>Creation Date:</td>
                  <td>
                    <strong>
                      {{ getGeneralInfo.creationDate | dateFilter }}
                    </strong>
                  </td>
                </tr><!--creationDate-->
              </tbody>
            </table>
          </div>
        </div>
        <cp-comments
          v-if="getGeneralInfo.comment"
          v-model="model.comment"
          custom-height="200px"
          :comments-array="commentsArray"
        />
      </cp-input-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import states from '@/utilities/us-states-list';
import { countriesList as countries } from '~/pages/securitize-id/components/options';
import CpComments from '~/components/common/standalone-components/cp-comments';
import cpInputContainer from '@/components/common/cpInputContainer';

export default {
  name: 'BrokerDealerGeneralInformation',
  components: {
    cpInputContainer,
    CpComments,
  },
  data() {
    return {
      isLoading: false,
      states,
      countries,
      openDate: new Date('1990-01-01'),
      model: {},
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
    stateOptions() {
      return Object.keys(this.states)
        .map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions.find(el => el.value === this.model.state);
      return currentState ? currentState.text : null;
    },
    isIndividual() {
      return this.model.investorType === 'individual';
    },
    isEntity() {
      return this.model.investorType === 'entity';
    },
    commentsArray() {
      return (this.getGeneralInfo.comment || '').split('\n');
    },
    withoutIssuers() {
      return !this.getGeneralInfo.issuersData.length;
    },
  },
  created() {
    this.model = { ...this.getGeneralInfo };
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getBrokerDealerInvestorId']),
    getCurrentCountryName(field) {
      const country = this.countries.find(item => item.value === this.getGeneralInfo[field]);
      return country ? country.text : null;
    },
  },
};
</script>
<style scoped lang="scss">
  .investor-type-field {
    padding: 10px 24px;
    align-items: baseline;
    span {
      margin-right: 5px;
    }
    div {
      width: 76%;
    }
  }
  .full-width {
    margin: -24px;
  }
  .investor-type-value {
    text-transform: capitalize;
  }
  .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background: #fff;
      opacity: 0.4;
    }
</style>
