<template>
  <div
    id="account-status"
    class="card mb-5"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('brokerDealer.investorDetails.verificationTab.accountTitle') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          :disable-edit="updatingStatus"
          @changeMode="changeMode"
          @update="save"
        />
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex flex-column flex-wrap w-50 mb-4">
        <h6>Signature: Enter the name of the approver - {{ operatorName }} </h6>
        <cp-editable-input
          v-model="model.signature"
          root-class="w-50"
          :edit-mode="editMode"
          :text="getGeneralInfo.accountStatusSignature"
          strong
        />
      </div>
      <div class="d-flex align-items-start justify-content-between flex-wrap">
        <div class="d-flex align-items-center">
          <div class="status-current mr-3">
            <div class="text-muted small">
              {{ $t('brokerDealer.investorDetails.verificationTab.accountInfo') }}
            </div>
          </div>
          <cp-editable-input
            id="account-status-select"
            v-model="model.status"
            :edit-mode="editMode"
            :text="getGeneralInfo.accountStatus"
            :options="accountStatusesOptions"
            :text-class="accountStatuses[getGeneralInfo.accountStatus]"
          />
        </div>
      </div>
      <account-status-log-table
        :key="getGeneralInfo.accountStatus"
        :investor-id="getGeneralInfo.investorId"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { accountStatuses, suitabilityLogTableFields, account } from '@/pages/broker-dealer/investors/components/options';
import CpEditButtons from '@/components/common/edit-buttons';
import CpEditableMixin from '@/mixins/editable-component';
import CpEditableInput from '@/components/common/editable-input';
import AccountStatusLogTable from '@/pages/broker-dealer/investors/components/details/account/logs-table';

export default {
  name: 'AccountDetails',
  components: {
    AccountStatusLogTable,
    CpEditableInput,
    CpEditButtons,
  },
  mixins: [
    CpEditableMixin,
  ],
  data() {
    return {
      accountStatuses,
      suitabilityLogTableFields,
      accountStatusesOptions: account.statuses,
      updatingStatus: false,
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
    ...mapGetters('currentOperator', ['operatorData', 'viewData']),
    operatorName() {
      return this.viewData.getName ? this.viewData.getName() : this.operatorData.name;
    },
  },
  created() {
    this.model = {
      status: this.getGeneralInfo.accountStatus,
      signature: this.getGeneralInfo.accountStatusSignature,
    };
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['updateAccountStatus', 'getBrokerDealerInvestorId']),
    async save() {
      if (!this.updatingStatus && this.model.status !== this.getGeneralInfo.accountStatus) {
        const params = {
          investorId: this.getGeneralInfo.investorId,
          ...this.model,
        };
        this.updatingStatus = true;
        this.updateAccountStatus(params)
          .then(() => {
            this.getBrokerDealerInvestorId({ investorId: this.getGeneralInfo.investorId })
              .finally(() => {
                this.updatingStatus = false;
                this.model = {
                  status: this.getGeneralInfo.accountStatus,
                  signature: this.getGeneralInfo.accountStatusSignature,
                };
                this.changeMode();
              });
          });
      }
    },
  },
};
</script>

<style lang="scss">
  #account-status {
    .bg-grey {
      background-color: rgba(10, 23, 39, 0.025)
    }
    .badge-grey {
      text-transform: capitalize;
      background-color: #dee0e2;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-orange {
      text-transform: capitalize;
      background-color: #F5A01D;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-yellow {
      text-transform: capitalize;
      background-color: #ffd950;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-green {
      text-transform: capitalize;
      background-color: #07bc77;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-red {
      text-transform: capitalize;
      background-color: #f7321f;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
  }
</style>
