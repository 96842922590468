import { render, staticRenderFns } from "./investor-details.vue?vue&type=template&id=ed6e789c&scoped=true&"
import script from "./investor-details.vue?vue&type=script&lang=js&"
export * from "./investor-details.vue?vue&type=script&lang=js&"
import style0 from "~/vendor/styles/pages/users.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./investor-details.vue?vue&type=style&index=1&id=ed6e789c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6e789c",
  null
  
)

export default component.exports