export default [
  {
    key: 'signerType',
    label: 'Legal Signers Type',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'name',
    label: 'Name',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'taxId',
    label: 'ID',
    tdClass: 'align-middle',
    thClass: 'align-middle',
  },
  {
    key: 'actions',
    label: '',
    tdClass: 'text-nowrap d-inline',
  },
];
