<template>
  <div>
    <div class="card mb-5 fundraise-round">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              Key Parties
            </h4>
          </div>
        </div>
      </div>
      <cp-table
        ref="cpTable"
        without-pagination
        without-search
        get-data-action="brokerDealerInvestors/getLegalSignersList"
        :url-params="urlParams"
        :fields="fields"
      >
        <template
          slot="name"
          slot-scope="{ rowData: {item}}"
        >
          <span v-if="item.signerType === 'entity'">{{ item.legalName }}</span>
          <span v-if="item.signerType === 'individual'">{{ item.individualName.firstName }} {{ item.individualName.lastName }}</span>
        </template>
        <template
          slot="taxId"
          slot-scope="{ rowData: {item}}"
        >
          <span v-if="item.signerType === 'entity'">{{ item.taxId }}</span>
          <span v-if="item.signerType === 'individual'"> - </span>
        </template>
        <template
          slot="actions"
          slot-scope="{ rowData }"
        >
          <div class="d-flex">
            <cp-button
              variant="default btn-xs icon-btn md-btn-flat"
              @click="$emit('onViewElem', rowData.item)"
            >
              <i class="ion ion-ios-eye" />
            </cp-button>
          </div>
        </template>
      </cp-table>
    </div>
  </div>
</template>

<script>
import CpTable from '~/components/shared/cp-table';
import fields from './fields';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'BrokerDealerLegalSignersTable',
  components: {
    CpButton,
    CpTable,
  },
  props: {
    isUpdateTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields,
      urlParams: { investorId: this.$route.params.investorId },
      currentRound: {},
      tableData: [],
    };
  },
  watch: {
    isUpdateTable(val) {
      if (val) {
        this.$refs.cpTable._updateTableData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .fundraise-round {
        .table {
            tr {
                td:nth-child(8), td:nth-child(9) {
                    white-space: nowrap;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .card-header {
            border-bottom: none;
        }
    }
</style>
