<template>
  <div
    id="accreditation-status"
    class="card mb-5"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('brokerDealer.investorDetails.verificationTab.accreditationStatusTitle') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          @changeMode="changeMode"
          @update="save"
        />
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex align-items-start justify-content-between flex-wrap">
        <div class="d-flex align-items-center">
          <div class="status-current mr-3">
            <div class="text-muted small">
              {{ $t('brokerDealer.investorDetails.verificationTab.accreditationStatusInfo') }}
            </div>
          </div>
          <cp-editable-input
            id="accreditation-status-select"
            v-model="model.status"
            :edit-mode="editMode"
            :text="getGeneralInfo.accreditationStatus"
            :options="accreditationStatusesOptions"
            :text-class="accreditationStatuses[getGeneralInfo.accreditationStatus]"
          />
        </div>
        <div class="d-flex justify-content-between align-items-center p-3 bg-grey w-50">
          <div class="d-flex align-items-center">
            <i class="ion ion-ios-paper fa-2x mr-2" />
            <span class="mr-3">
              Signed letter from attorney
            </span>
          </div>
          <cp-button
            :disabled="getGeneralInfo.accreditationStatus !== 'verified' || !getGeneralInfo.accreditationDocument"
            size="sm"
            variant="primary"
            icon="ion ion-ios-arrow-round-down"
            @click="downloadAccreditationLetter"
          >
            {{ $t('brokerDealer.investorDetails.verificationTab.download') }}
          </cp-button>
        </div>
      </div>
      <cp-comments
        v-if="getGeneralInfo.accreditationStatusComment || editMode"
        v-model="model.comment"
        custom-height="200px"
        :is-editable="editMode"
        :comments-array="[getGeneralInfo.accreditationStatusComment]"
      />
      <div class="d-flex justify-content-between flex-1 mt-3">
        <div class="d-flex justify-content-between align-items-center p-3 pr-lg-5 bg-grey w-50 mr-3">
          <span>
            {{ $t('brokerDealer.investorDetails.verificationTab.lastAccreditationChange') }}
          </span>
          <span>
            <strong>
              {{ getGeneralInfo.accreditationStatusChangeDate | dateFilter }}
            </strong>
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center p-3 pr-lg-5 bg-grey w-50">
          <span>
            {{ $t('brokerDealer.investorDetails.verificationTab.accreditationExpiryDate') }}
          </span>
          <span>
            <strong v-if="getGeneralInfo.accreditationStatus === 'verified'">
              {{ getGeneralInfo.accreditationDocumentExpirationDate | dateFilter }}
            </strong>
            <strong v-else> - </strong>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { accreditationStatuses, accreditation } from '@/pages/broker-dealer/investors/components/options';
import CpEditButtons from '@/components/common/edit-buttons';
import CpEditableMixin from '@/mixins/editable-component';
import CpEditableInput from '@/components/common/editable-input';
import CpComments from '@/components/common/standalone-components/cp-comments';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'BrokerDealerAccreditationDetails',
  components: {
    CpButton,
    CpComments,
    CpEditableInput,
    CpEditButtons,
  },
  mixins: [
    CpEditableMixin,
  ],
  data() {
    return {
      accreditationStatuses,
      accreditationStatusesOptions: accreditation.statuses,
      model: {},
      updatingStatus: false,
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
  },
  created() {
    this.model = {
      status: this.getGeneralInfo.accreditationStatus,
      comment: this.getGeneralInfo.accreditationStatusComment,
    };
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['updateInvestorAccreditationStatus', 'getBrokerDealerInvestorId']),
    async save() {
      if (!this.updatingStatus && this.model.status !== this.getGeneralInfo.accreditationStatus) {
        const params = {
          investorId: this.getGeneralInfo.investorId,
          comment: this.model.comment,
          status: this.model.status,
        };
        this.updatingStatus = true;
        await this.updateInvestorAccreditationStatus(params);
        await this.getBrokerDealerInvestorId({ investorId: this.getGeneralInfo.investorId });
        this.updatingStatus = false;
        this.changeMode();
      }
    },
    downloadAccreditationLetter(e) {
      e.preventDefault();
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = this.getGeneralInfo.accreditationDocument.split('/').pop();
      link.href = this.getGeneralInfo.accreditationDocument;
      link.click();
    },
  },
};
</script>

<style lang="scss">
  #accreditation-status {
    .bg-grey {
      background-color: rgba(10, 23, 39, 0.025)
    }
    .badge-grey {
      text-transform: capitalize;
      background-color: #dee0e2;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-orange {
      text-transform: capitalize;
      background-color: #F5A01D;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-yellow {
      text-transform: capitalize;
      background-color: #ffd950;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-green {
      text-transform: capitalize;
      background-color: #07bc77;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-red {
      text-transform: capitalize;
      background-color: #f7321f;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
  }
</style>
