<template>
  <div
    id="suitability-status"
    class="card mb-5"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('brokerDealer.investorDetails.verificationTab.suitabilityTitle') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          @changeMode="toggleEditMode"
          @update="save"
        />
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex align-items-start justify-content-between flex-wrap">
        <div class="d-flex align-items-center">
          <div class="status-current mr-3">
            <div class="text-muted small">
              {{ $t('brokerDealer.investorDetails.verificationTab.suitabilityInfo') }}
            </div>
          </div>
          <cp-editable-input
            id="suitability-status-select"
            v-model="model.status"
            :edit-mode="editMode"
            :text="getGeneralInfo.suitability.status"
            :options="suitabilityStatusesOptions"
            :text-class="suitabilityStatuses[getGeneralInfo.suitability.status]"
          />
        </div>
        <div class="d-flex justify-content-between align-items-center p-3 bg-grey w-50">
          <div class="d-flex align-items-center">
            <i class="ion ion-ios-paper fa-2x mr-2" />
            <span class="mr-3">
              Suitability answers
            </span>
          </div>
          <cp-button
            v-if="getGeneralInfo.suitability.answers"
            size="sm"
            variant="primary"
            icon="ion ion-ios-arrow-round-down"
            @click="downloadAnswers"
          >
            {{ $t('brokerDealer.investorDetails.verificationTab.download') }}
          </cp-button>
        </div>
      </div>
      <suitability-status-log-table
        :key="getGeneralInfo.suitability.status"
        :investor-id="getGeneralInfo.investorId"
      />
      <cp-comments
        v-if="getGeneralInfo.suitability.comment || editMode"
        v-model="model.comment"
        custom-height="200px"
        :is-editable="editMode"
        :comments-array="[getGeneralInfo.suitability.comment]"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import FileSaver from 'file-saver';
import { mapActions, mapGetters } from 'vuex';
import { suitabilityStatuses, suitabilityLogTableFields, suitability } from '@/pages/broker-dealer/investors/components/options';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpEditButtons from '@/components/common/edit-buttons';
import SuitabilityStatusLogTable from '@/pages/broker-dealer/investors/components/details/suitability/logs-table';
import CpComments from '@/components/common/standalone-components/cp-comments';
import CpEditableMixin from '@/mixins/editable-component';
import CpEditableInput from '@/components/common/editable-input';

export default {
  name: 'SuitabilityDetails',
  components: {
    CpEditableInput,
    CpComments,
    SuitabilityStatusLogTable,
    CpEditButtons,
    CpButton,
  },
  mixins: [
    CpEditableMixin,
  ],
  data() {
    return {
      suitabilityStatuses,
      suitabilityLogTableFields,
      suitabilityStatusesOptions: suitability.statuses,
      model: {},
      updatingStatus: false,
    };
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['updateSuitabilityStatus', 'getBrokerDealerInvestorId']),
    downloadAnswers() {
      const regex = new RegExp(/([A-Z]{2,})/);
      const investorName = this.getGeneralInfo.investorType === 'individual'
        ? `${this.getGeneralInfo.fullName || ''}`
        : this.getGeneralInfo.name || '';
      const suitabilityAnswers = _.omit(JSON.parse(this.getGeneralInfo.suitability.answers), ['createdAt', 'updatedAt', '__v']);

      const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
      const humanizeCamelCaseString = str => str.split(regex).map(char => (
                regex.test(char)
                  ? char
                  : char.replace(/([A-Z])/g, ' $1').toLowerCase()
        )).join(' ');
      const convertObjectToIndentedString = obj => Object.entries(obj).reduce((res, [key, value]) => {
          const fieldName = capitalize(humanizeCamelCaseString(key));

          if (value && typeof value === 'object' && !Array.isArray(value)) {
            value = convertObjectToIndentedString(value);
            res = `${res}
            
              ${fieldName}: ${value}`;
          } else if (Array.isArray(value)) {
            res = `${res}

            ${fieldName}:

              ${value.join(`

            `)}`;
          } else {
            res = `${res}
            
                ${fieldName}: ${value}`;
          }

          return res;
        }, '');

      const suitabilityAnswersReadbleString = convertObjectToIndentedString(suitabilityAnswers);
      const blob = new Blob([suitabilityAnswersReadbleString]);

      FileSaver.saveAs(blob, `${investorName}-suitability-answers.txt`);
    },
    toggleEditMode() {
      this.changeMode();
      this.model = { ...this.getGeneralInfo.suitability };
    },
    async save() {
      if (this.model.status !== this.getGeneralInfo.suitability.status) {
        const params = {
          investorId: this.getGeneralInfo.investorId,
          comment: this.model.comment,
          status: this.model.status,
        };
        this.updatingStatus = true;
        await this.updateSuitabilityStatus(params);
        await this.getBrokerDealerInvestorId({ investorId: this.getGeneralInfo.investorId });
        this.updatingStatus = false;
        this.toggleEditMode();
      }
    },
  },
};
</script>

<style lang="scss">
  #suitability-status {
    .bg-grey {
      background-color: rgba(10, 23, 39, 0.025)
    }
    .badge-grey {
      text-transform: capitalize;
      background-color: #dee0e2;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-orange {
      text-transform: capitalize;
      background-color: #F5A01D;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-yellow {
      text-transform: capitalize;
      background-color: #ffd950;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-green {
      text-transform: capitalize;
      background-color: #07bc77;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
    .badge-red {
      text-transform: capitalize;
      background-color: #f7321f;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: #FFF;
    }
  }
</style>
