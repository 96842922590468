<template>
  <div>
    <investor-details-kyc />
    <suitability-details />
    <suitability-eu-details />
    <account-details />
    <account-eu-details />
    <broker-dealer-accreditation-details />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InvestorDetailsKyc from '@/pages/broker-dealer/investors/components/details/kyc/kyc-details';
import SuitabilityDetails from '@/pages/broker-dealer/investors/components/details/suitability/index';
import SuitabilityEuDetails from '@/pages/broker-dealer/investors/components/details/suitability-eu/index';
import AccountDetails from '@/pages/broker-dealer/investors/components/details/account/index';
import AccountEuDetails from '@/pages/broker-dealer/investors/components/details/account-eu/index';
import BrokerDealerAccreditationDetails
  from '@/pages/broker-dealer/investors/components/details/accreditation-status/index';

export default {
  name: 'VerificationTab',
  components: {
    BrokerDealerAccreditationDetails, AccountDetails, SuitabilityDetails, SuitabilityEuDetails, InvestorDetailsKyc, AccountEuDetails,
  },
  computed: {
    ...mapGetters('brokerDealerInvestors', ['getGeneralInfo']),
  },
};
</script>
