<template>
  <b-tabs
    class="nav-tabs-top nav-responsive-sm"
    content-class="bg-transparent mt-4"
    active-nav-item-class="bg-transparent"
  >
    <b-tab
      title="General information"
    >
      <general-info-tab :investor-type="investorType" />
    </b-tab>
    <b-tab title="Verification">
      <verification-tab />
    </b-tab>
  </b-tabs>
</template>

<script>
import GeneralInfoTab from '@/pages/broker-dealer/investors/components/details/tabs/general-info-tab';
import VerificationTab from '@/pages/broker-dealer/investors/components/details/tabs/verification-tab';

export default {
  name: 'MarketInvestorTabs',
  components: { VerificationTab, GeneralInfoTab },
  props: {
    investorType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .nav-tabs-top {
    div {
      .nav.nav-tabs {
        border-bottom: 1px solid #e5e5e5 !important;
      }
      .nav {
        .nav-link.active {
           border: none !important;
           border-color: #55bbc2 !important;
           border-bottom: 2px solid #55bbc2 !important;
        }
      }
    }
  }
</style>
